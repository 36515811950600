<template>
  <div class="order">
    <search-header></search-header>
    <div class="container pt-3 pb-3 p-0" >
      <div class="row p-0"  v-for="(m,i) in order" :key="i">
            <div class="col-12 bg-white rounded p-0" >
                <div class="border-bottom p-3">订单信息</div>
                <div class="container p-0" >
                    <div class="row pb-3">
                        <div class="col-4 px-3 mt-3">
                            <span>流水号：</span>
                            <span class="text-secondary">{{m.id}}</span>
                        </div>
                        <div class="col-4 px-3 mt-3">
                            <span>订单类型：</span>
                            <span class="text-secondary">{{getorderStatusValue(m.bizStatus)}}</span>
                        </div>
                        <div class="col-4 px-3 mt-3">
                            <span>交易状态：</span>
                            <span class="text-secondary">{{getreserveStatusValue(m.tradeStatus)}}</span>
                        </div>
                        <div class="col-4 px-3 mt-3">
                            <span>服务费：</span>
                            <span class="text-danger">免费</span>
                        </div>
                        <div class="col-4 px-3 mt-3">
                            <span>交易时间：</span>
                            <span class="text-secondary">{{m.createdAt}}</span>
                        </div>
                        <div class="col-4 px-3 mt-3">
                            <span>下单时间：</span>
                            <span class="text-secondary">{{m.createdAt}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 bg-white rounded p-0 mt-3" >
                <div class="border-bottom p-3"   >地块信息</div>
                <div class="clearfix p-3"  >
                    <div class="float-left">
                        <van-image width="200" height="125" fit="cover" lazy-load :src="m.userNeedsFiles[0].fileUrl" />
                    </div>
                    <div class="float-left pl-2 w-65">
                        <router-link to="/servicecentre">{{m.userNeedsForLet.title}}</router-link>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">地块编号：</span>
                            <span class="ml-2">{{m.userNeedsForLet.id}}</span>
                        </div>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">土地用途：</span>
                            <span class="ml-2"  >{{getUseLandValue(landUse)}}</span>

                            <span class="text-secondary ml-4">流转类型：</span>
                            <span class="ml-2">{{getcirculationTypeValue(m.userNeedsForLet.flowType)}}</span>

                            <span class="text-secondary ml-4">流转年限：</span>
                            <span class="ml-2">{{m.userNeedsForLet.years}} 年</span>

                            <span class="text-secondary ml-4">土地面积：</span>
                            <span class="ml-2">{{m.userNeedsForLet.measureArea}} 亩</span>
                        </div>
                        <div class="f-14 mt-1" v-if="m.userNeedsForLet.serviceTag">
                            <span class="text-secondary">适合经营：</span>
                            <span class="ml-2">{{getTagsListValue(m.userNeedsForLet.serviceTag)}}</span>
                        </div>
                        
                        <div class="f-14 mt-1">
                            <span class="text-secondary">土地地点：</span>
                            <span class="ml-2">{{m.userNeedsForLet.address}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 bg-white rounded p-0 mt-3">
                <div class="border-bottom p-3">服务商信息</div> 
                <div class="clearfix p-3">
                    <div class="float-left">
                        <van-image width="200" height="125" fit="cover" lazy-load :src="require('@/static/img/s_1.png')" />
                    </div>
                    <div class="float-left pl-2 w-65">
                        <router-link to="/servicecentre">江西省抚州市土地流转中心</router-link>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">承接经纪人：</span>
                            <span class="ml-2">吴先生</span>
                        </div>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">经纪人电话：</span>
                            <span class="ml-2">15044885911</span>
                        </div>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">服务区域：</span>
                            <span class="ml-2">江西抚州市</span>
                        </div>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">企业地址：</span>
                            <span class="ml-2">江西省抚州市临川区上顿渡镇东方威尼斯302室</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="m.tradeStatus == 0" class="col-12 bg-white rounded p-0 mt-3">
                <div class="border-bottom p-3">支付方式</div> 
                <div class="clearfix p-3">
                    <b-form-radio-group
                        v-model="payWay"
                    >
                        <div @click="payWay = 'alipay'" class="float-left border rounded-10 cursor-default p-4" :class="{'border-success' : payWay == 'alipay'}" style="position: relative;">
                            <b-form-radio value="alipay" style="position: absolute;top:5px;left:10px"></b-form-radio>
                            <van-image width="144" height="50" fit="cover" lazy-load :src="require('@/assets/icon/zfb.png')" />
                        </div>
                        <div @click="payWay = 'wechat'" class="float-left border rounded-10 cursor-default p-4 ml-4" :class="{'border-success' : payWay == 'wechat'}" style="position: relative;">
                            <b-form-radio value="wechat" style="position: absolute;top:5px;left:10px"></b-form-radio>
                            <van-image width="144" height="50" fit="cover" lazy-load :src="require('@/assets/icon/wxsm.png')" />
                        </div>
                        <div @click="payWay = 'balance'" class="float-left border rounded-10 cursor-default p-4 ml-4" :class="{'border-success' : payWay == 'balance'}" style="position: relative;">
                            <b-form-radio value="balance" style="position: absolute;top:5px;left:10px"></b-form-radio>
                            <div class="balance">
                                <div>余额支付</div>
                                <div>
                                    <span>账户余额：</span>
                                    <span class="text-success">0元</span>
                                </div>
                            </div>
                        </div>
                    </b-form-radio-group>
                </div>
                <div class="clearfix p-3">
                    <div class="float-right">
                        <span>应付金额: </span>
                        <span class="text-danger">{{m.userNeedsForLet.price}}万元</span>
                        <b-button class="ml-2" variant="danger" @click="payment(m.id)">立即支付</b-button>
                    </div>
                </div>
            </div>
      </div>
    </div>
    <map-footer></map-footer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
        payWay: '',
        bizId: '',
        order:[],
      landUse:'',

    };
  },
  computed:{
    ...mapGetters(["dict"]),
    getpriceUnitValue () {
      return function(id) {
        if(this.dict && this.dict.priceUnit) {
          let m = this.dict.priceUnit.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
    getTagsListValue() {
      return function(j) {
        let b= '';
        if(this.dict && this.dict.landTags) {
          var tag = j.split(',');
          var valueArr = this.dict.landTags.filter(x=> tag.includes(x.id )).map(x=>{return x.text});
          b = valueArr.join('/');
        }
        return b;
      }
    },
    getcirculationTypeValue () {
      return function(id) {
        if(this.dict && this.dict.circulationType) {
          let m = this.dict.circulationType.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
    getreserveTypeValue () {
      return function(id) {
        if(this.dict && this.dict.reserveType) {
          let m = this.dict.reserveType.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
    getreserveTypeValue () {
      return function(id) {
        if(this.dict && this.dict.reserveType) {
          let m = this.dict.reserveType.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
    getorderStatusValue () {
      return function(id) {
        if(this.dict && this.dict.orderStatus) {
          let m = this.dict.orderStatus.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
    getreserveStatusValue () {
      return function(id) {
        if(this.dict && this.dict.reserveStatus) {
          let m = this.dict.reserveStatus.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
    getUseLandValue()	{
      return function(code) {
        let value = '';
        if(this.dict && this.dict.landUseType) {
          let lp = null, lc = null;
          lp = this.dict.landUseType.find(x => x.children.find(y=> y.id  == code));
          if(lp) {
            value = lp.text;
            lc = lp.children.find(x=> x.id == code);
            if(lc) {
              value = value + '/' + lc.text;
            }
          }
        }
        return value
      }
    },
  },
  methods: {
    findOrder(){
      //查询带看订单详情
      this.$http.post(this.$api.order.findOrder+this.bizId).then(res => {
        if (res.code == 1) {
          this.order=res.result;
        }
      })
      //查询土地用途

      this.$http.post(this.$api.supply.getLandDetail+'/'+this.bizId).then(res => {
        if (res.code == 1) {
          debugger
          //是农房 就取农房的用途
          if (res.result.classId==3){
            this.landUse=res.result.userNeedsFarms[0].landUse;
          }
          //是农用地就取农用地的用途
          if (res.result.classId==1) {
            this.landUse = res.result.userNeedsAgriculturalLands[0].landUse;
          }
        }
      })
    },
    //支付
    payment(id){
      this.$http.post(this.$api.order.payOrder+id).then(res => {
        if (res.code == 1) {
           this.$model.toast('支付成功',2000);
           this.order[0].tradeStatus = 1;
        }
      })
    },



  },

  created() {
    if(this.$route.query.bizId) {
      this.bizId = this.$route.query.bizId;
    }
    this.findOrder();
  },
};
</script>

<style scoped>
    .order {
        background-color: #f5f5f5;
    }
    .balance {
        width:144px;height:55px;display: flex; flex-direction: column; justify-content: center;
    }
</style>
